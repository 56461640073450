import React, { useEffect } from 'react';
import { useParams, useStateView } from '../../hooks';
import { localeView } from '../../state/views';

import Dropdown from '../Dropdown';
import { languageDropdown } from './styles.scss';
import { cn } from '../../utils';
import { string } from 'prop-types';

const localeItems = [
  {
    label: 'English',
    value: 'en',
    smallLabel: 'EN'
  },
  {
    label: 'Español',
    value: 'es',
    smallLabel: 'ES'
  }
];

const LanguageSelect = ({ className }) => {
  const { lang } = useParams();
  const [ locale, setLocale ] = useStateView(localeView);

  useEffect(() => {
    if (lang && lang !== locale) {
      setLocale(lang);
      location?.reload();
    }
  }, [ lang ]);

  const items = localeItems.map(item => ({ ...item, isDefault: item.value === locale }));

  const handleChange = ({ value }) => {
    const htmlElement = document?.querySelector('html');

    if (htmlElement) {
      htmlElement.setAttribute('lang', value);
    }

    if (value !== locale) {
      setLocale(value);
    }
  };

  return <Dropdown 
    data-action="select language"
    items={items}
    outline={false}
    onChange={handleChange}
    name="preferredLanguage"
    className={cn(className, languageDropdown)}
    hasSmallLabel
  />
};

LanguageSelect.propTypes = {
  className: string
};

export default LanguageSelect;
