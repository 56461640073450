export default {
  en: {
    paymentFrequencies: {
      weekly: 'Weekly',
      everyTwoWeeks: 'Biweekly',
      monthly: 'Monthly',
      biweekly: 'Biweekly'
    },
    selectPaymentFrequencies: {
      weekly: 'Select weekly',
      everyTwoWeeks: 'Select every 2 weeks',
      monthly: 'Select monthly',
      biweekly: 'Select biweekly'
    },
    payments: 'payment{multiplePayments?s:}',
    paymentPlan: 'Payment Plan',
    paymentsOf: '{finalPayment?plus :}{numPayments?numPayments:1} {finalPayment?final :}payment{multiplePayments?s:} of',
    resumedPaymentOf: 'Plus final payment of',
    totalAmount: 'Total Amount',
    amount: 'Amount',
    frequency: 'Frequency',
    fullBalance: 'Full Balance',
    planDetail: 'Plan Detail',
    of: 'of',
    every: 'Every',
    emptyStatePlan: 'Create a payment plan or pay in full to take control of your account',
    paymentContactNotice: 'To modify or cancel a recurring or scheduled payment, please call us at {telLink}',
    paymentContactNumber: '866-539-7525',
    notAvailable: 'Not available at this time',
    continue: 'Continue',
    confirm: 'Confirm',
    viewAll: 'View all payments',
    remainingBalance: 'Remaining Balance',
    alreadyPaid: 'You already paid',
    authAgreement: {
      ach: `I authorize Spring Oaks Capital, LLC hereinafter to initiate a single or recurring ACH/electronic debit to my account
      in the amount(s) and date(s) set forth above. I agree that ACH transactions I authorize comply with all applicable law. 
      Furthermore, I assert that I am the owner or an authorized signer of the bank account provided. If you wish to rescind this 
      authorization or cancel payment arrangements, you may write Spring Oaks Capital, LLC at {poText} during our normal business hours of {businessHours}`,
      card: `I authorize Spring Oaks Capital, LLC hereinafter to initiate a single or recurring credit/debit card charge to my account set forth above in the amount(s) and date(s)
      set forth above. I agree that credit/debit card transactions I authorize comply with all applicable law.
      For recurring payment arrangements, I understand that this authorization will remain in full force and effect until
      I notify Spring Oaks Capital, LLC in writing or by phone that I wish to revoke this authorization.
      If you wish to rescind this authorization or cancel payment arrangements, you may write Spring Oaks Capital, LLC at
      {poText} during our normal hours of {businessHours}`,
      cta: `To complete the payment process, click the “{buttonText}“ button.`,
      print: `It is recommended that you print a copy of this authorization and maintain it for your records.`
    },
    poText: 'P.O. Box 1216, Chesapeake, VA 23327-1216 or call (866) 281-3065',
    businessHours: 'Monday through Thursday 8am-11pm ET, Friday 8am-8pm ET.',
    miniMiranda: 'This is a communication from a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose.',
    knowYourRights: 'Know your rights',
    backToHome: 'Back to Home',
    backToWebsite: 'Back to website',
    goBack: 'Go Back',
    contactUs: 'Contact Us',
    contactNumber: '866-539-7525',
    tryAgain: 'Try Again',
    gotIt: 'Got it!',
    notice: 'Notice',
    defaultCreditorName: 'Spring Oaks Capital, LLC',
    creditDisclosure: 'If Spring Oaks Capital, LLC is reporting this account to a credit reporting agency, {receiptText} of the final payment resolving the account, Spring Oaks Capital, LLC will request deletion of its tradeline from your credit report.',
    receiptText: 'within 30 days of receipt',
    springOaksCapital: 'Spring Oaks Capital, LLC',
    copyrightNotice: '© 2024 Spring Oaks Capital, LLC, All Rights Reserved.',
    everyOther: 'Every other',
    ofTheMonth: 'of the month',
    currentBalance: 'Current Balance',
    upcomingPayment: 'Upcoming payment',
    dueOn: 'Due on',
    tooltip: 'If your selected payment date doesn\'t occur in every \nmonth (e.g., 30th or 31st), it will be processed on the last \navailable day of that month.',
    emailDisclosure: 'By providing your email address, you consent to receiving correspondence via email from Spring Oaks Capital, LLC or any entities that Spring Oaks Capital, LLC places your account with. This consent will nullify any previous unsubscribe requests received by Spring Oaks Capital, LLC. To protect your privacy, please do not submit any email accounts that are shared or are work email accounts.',
    accounts: 'Accounts',
  },
  es: {
    paymentFrequencies: {
      weekly: 'Semanal',
      everyTwoWeeks: 'Quincenal',
      monthly: 'Mensual',
      biweekly: 'Quincenal'
    },
    selectPaymentFrequencies: {
      weekly: 'Seleccionar Semanal',
      everyTwoWeeks: 'Seleccionar Quincenal',
      monthly: 'Seleccionar Mensual',
      biweekly: 'Seleccionar Quincenal'
    },
    payments: 'pago{multiplePayments?s:}',
    paymentPlan: 'Plan de pago',
    paymentsOf: '{finalPayment?más :}{numPayments?numPayments:1} pago{multiplePayments?s:} {finalPayment?final :}de',
    resumedPaymentOf: 'Y pago final de',
    totalAmount: 'Monto total',
    amount: 'Monto',
    frequency: 'Frecuencia',
    fullBalance: 'Saldo total',
    planDetail: 'Detalle del plan',
    of: 'de',
    every: 'Cada',
    emptyStatePlan: 'Cree un plan de pago o pague la totalidad para tomar el control de su cuenta',
    paymentContactNotice: 'Para modificar o cancelar un pago recurrente o programado, llámenos al {telLink}',
    paymentContactNumber: '866-539-7525',
    notAvailable: 'No está disponible en este momento',
    continue: 'Continuar',
    confirm: 'Confirmar',
    viewAll: 'Ver todos los pagos',
    remainingBalance: 'Saldo restante',
    alreadyPaid: 'Ya pagó',
    authAgreement: {
      ach: `Autorizo a Spring Oaks Capital, LLC en lo sucesivo a iniciar un débito electrónico/de la ACH único o recurrente en mi 
      cuenta por el/los monto(s) y en la(s)fecha(s) que se establecen arriba. Acepto que las transacciones de la ACH que autorizo 
      cumplen con todas las leyes aplicables. Además, afirmo que soy el titular o firmante autorizado de la cuenta bancaria 
      proporcionada. Si desea rescindir esta autorización o cancelar los acuerdos de pago, puede escribir a Spring Oaks Capital, 
      LLC a {poText} durante nuestro horario de atención normal de {businessHours}`,
      card: `Autorizo a Spring Oaks Capital, LLC en lo sucesivo a iniciar un cargo único o recurrente de tarjeta de crédito/débito 
      en mi cuenta establecida anteriormente por el/los monto(s) y en la(s) fecha(s) que se establecen arriba. Acepto que 
      las transacciones con tarjeta de crédito/débito que autorizo cumplen con todas las leyes aplicables. Para acuerdos de pago 
      recurrentes, entiendo que esta autorización permanecerá en pleno vigor y efecto hasta que notifique a 
      Spring Oaks Capital, LLC por escrito o por teléfono que deseo revocar esta autorización. Si desea rescindir esta autorización 
      ocancelar los acuerdos de pago, puede escribir a Spring Oaks Capital, LLC a {poText} durante nuestro horario de atención normal de {businessHours}`,
      cta: 'Para completar el proceso de pago, haga clic en el botón “{buttonText}“.',
      print: 'Se recomienda que imprima una copia de esta autorización y la conserve para sus registros.'
    },
    poText: 'P.O. Box 1216, Chesapeake, VA 23327-1216 o llamar al (866) 281-3065',
    businessHours: 'lunes a jueves de 8:00 a.m. a 11:00 p.m. hora del este, y los viernes de 8:00 a.m. a 8:00 p.m. hora del este.',
    miniMiranda: 'Esta es una comunicación de un cobrador de deudas. Este es un intento de cobrar una deuda y cualquier información obtenida se utilizará para ese propósito.',
    knowYourRights: 'Conozca sus derechos',
    backToHome: 'Volver al Inicio',
    backToWebsite: 'Volver al sitio web',
    goBack: 'Volver',
    contactUs: 'Contáctenos',
    contactNumber: '866-539-7525',
    tryAgain: 'Intente de nuevo',
    gotIt: '¡Comprendo!',
    notice: 'Aviso',
    defaultCreditorName: 'Spring Oaks Capital, LLC',
    creditDisclosure: 'Si Spring Oaks Capital, LLC reporta esta cuenta a una agencia de informes de crédito {receiptText} final que resuelve la cuenta, Spring Oaks Capital, LLC, solicitará la eliminación de la línea de crédito de su informe de crédito.',
    receiptText: 'en el transcurso de 30 días a partir de la recepción del pago',
    springOaksCapital: 'Spring Oaks Capital, LLC',
    copyrightNotice: '© 2024 Spring Oaks Capital, LLC, Todos los derechos reservados.',
    everyOther: 'Cada dos',
    ofTheMonth: 'del mes',
    currentBalance: 'Saldo actual',
    tooltip: 'Si la fecha de pago seleccionada no ocurre cada mes (p. ej., el día 30.° o 31.°), se procesará en el último día disponible de ese mes.',
    emailDisclosure: 'Al proporcionar su dirección de correo electrónico, usted acepta recibir correspondencia por correo electrónico de Spring Oaks Capital, LLC o de cualquier entidad con la que Spring Oaks Capital, LLC coloque su cuenta. Este consentimiento anulará cualquier solicitud de cancelación de la suscripción previa que Spring Oaks Capital, LLC haya recibido. Para proteger su privacidad, no envíe ninguna cuenta de correo electrónico compartida o cuenta de correo electrónico laboral',
    accounts: 'Cuentas',
    upcomingPayment: 'Próximo pago',
    dueOn: 'Vence el'
  }
};
