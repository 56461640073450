import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useActivePaymentPlanStatus, useLocale, useOnce, useQuiqChat, useStateView } from '../../hooks';
import {
  accountView,
  activePaymentPlansView,
  paymentPlanView,
  selectedReferenceNumberView
} from '../../state/views';

import {
  debugConsole,
  formatCurrency,
  mixpanel,
  redirect,
  templateString
} from '../../utils';
import allMessages from './messages';

import { getPaymentPlans } from '../../service/paymentPlan';

import {
  Button,
  CreditDisclosure,
  LegalFooter,
  Modal,
  PaymentHistory,
  ProgressBar,
  Spinner
} from '../../components';

import {
  buttonRow,
  creditNotice,
  inlineCta,
  paidAmount,
  paymentMethodPrompt,
  viewPaymentPlans,
  homePage,
  infoWrapper,
  separator
} from './styles.scss';
import { paths } from '../../routes';
import { MIXPANEL_EVENTS, PAYMENT_PLAN_TYPES } from '../../constants';
import AccountDetailsBox from '../../components/AccountDetailsBox';
import PaymentDetailsBox from '../../components/PaymentDetailsBox';

const HomePage = () => {
  const [ account ] = useStateView(accountView);
  const [ activePaymentPlans, setActivePaymentPlans ] = useStateView(activePaymentPlansView);
  const [ , setPaymentPlan, clearPaymentPlan ] = useStateView(paymentPlanView);
  const [ selectedReferenceNumber ] = useStateView(selectedReferenceNumberView);
  const { formatMessage, messages } = useLocale(allMessages);

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewAccountDetailsPage));

  useQuiqChat({ debtId: account.accountId });

  const [ isCreditNoticeVisible, setCreditNoticeVisible ] = useState(false);

  const {
    accountholderFirstName: firstName,
    originalBalance,
    remainingBalance,
    creditReportAddDate,
    creditReportDeleteDate,
    isCreditReportingEnabled
  } = account;

  const showCreditNotice = isCreditReportingEnabled && creditReportAddDate && !creditReportDeleteDate;

  const alreadyPaid = Math.abs(originalBalance - remainingBalance);

  useEffect(() => {
    getPaymentPlans(account.accountId)
      .then(data => {
        mixpanel.set({ hasPaymentPlans: data?.paymentPlans?.length > 0 });
        setActivePaymentPlans(data);
      })
  }, [selectedReferenceNumber]);

  const {
    canCreatePlan,
    hasPromise,
    hasNoActivePlans,
    hasNonPromisePlans
  } = useActivePaymentPlanStatus();

  debugConsole.log(
    'Payment plan(s) status',
    {
      hasPromise,
      hasNoActivePlans,
      hasNonPromisePlans,
      canCreatePlan
    }
  );

  const handleClickAddPaymentMethod = () => {
    mixpanel.track(MIXPANEL_EVENTS.clickAddPaymentMethodToPromise);
  };

  if (!activePaymentPlans) return <Spinner />

  return <div className={homePage}>
    <h1>{ formatMessage(messages.welcome, { firstName }) }</h1>
    <h3>{messages.journey}</h3>
    <div className={infoWrapper}>
      <AccountDetailsBox
        hasPlan={!hasNoActivePlans && hasNonPromisePlans}
        paymentPlans={activePaymentPlans && activePaymentPlans.paymentPlans}
        detailsLabel={messages.accountDetails}
      />
      <PaymentDetailsBox
        hasPlan={!hasNoActivePlans && hasNonPromisePlans}
        amountPaid={alreadyPaid}
        remainingBalance={remainingBalance}
        originalBalance={originalBalance}
        detailsLabel={messages.accountDetails}
      />
    </div>
    <div className={separator}>
      <CreditDisclosure />
    </div>
    <LegalFooter showContactUs={false} />
  </div>
};

export default HomePage;
