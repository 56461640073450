import React, { useEffect, useState } from 'react';
import { getPaymentHistory } from '../../service/paymentPlan';
import { useStateView } from '../../hooks';
import { accountView, paymentHistoryView, paymentPlanView } from '../../state/views';

import { progressBox, shownMobile } from './styles.scss';
import Spinner from '../Spinner';
import ActivePlan from './ActivePlan';
import PaymentHistory from '../PaymentHistory';

import Expander from '../Expander';
import AccountDetails from '../AccountDetails';

const PaymentDetailsBox = ({
  hasPlan,
  amountPaid,
  remainingBalance,
  originalBalance,
  paymentHistory,
  detailsLabel
}) => {
  const [{ accountId: referenceNumber }] = useStateView(accountView);
  const [ history, setHistory ] = useStateView(paymentHistoryView);
  const [ activePaymentPlan ] = useStateView(paymentPlanView);
  const [ paymentStatus, setPaymentStatus] = useState({ paid: 0, total: 0 });
  const [ paymentsMadeTotal, setPaymentsMadeTotal ] = useState(amountPaid);

  useEffect(() => {
    if (activePaymentPlan?.paymentSchedule?.length) {
      const plansPaid = activePaymentPlan.paymentSchedule.filter(schedule => schedule.paymentStatus === 'PAID');
      setPaymentStatus({ paid: plansPaid.length, total: activePaymentPlan.planLength });

      plansPaid.length ? setPaymentsMadeTotal(plansPaid.reduce((total, { paymentAmount }) => total + paymentAmount), 0) : setPaymentsMadeTotal(0);
    }
  }, [activePaymentPlan]);

  const getCurrentValue = () => {
    if (activePaymentPlan?.paymentSchedule?.length) {
      return paymentStatus.paid / paymentStatus.total;
    }

    return amountPaid / originalBalance;
  }

  return (
    <>
      <div className={progressBox}>
        <ActivePlan
          hasPlan={hasPlan}
          percentage={getCurrentValue()*100}
          amountPaid={paymentsMadeTotal}
          remainingBalance={remainingBalance}
          paymentStatus={paymentStatus}
        />
        <PaymentHistory summarized length={4} />
      </div>
      <Expander className={shownMobile} label={detailsLabel}>
        <AccountDetails />
      </Expander>
    </>
  )
};

export default PaymentDetailsBox;
