export default {
  en: {
    title: 'Payments',
    description: 'Choose the best payment option by considering payment \namount, frequency, and how it fits into your budget.',
    empty: 'You don\’t have any payment plans',
    installments: 'Installments',
    nextPayments: 'Next Payments',
    addPaymentMethodCTA: 'Add Payment Method',
    payInFull: 'Pay in Full',
    createPlan: 'Create a Payment Plan',
    partialPayment: 'Make a Partial Plan',
    payInFullSubtitle: 'Pay your entire balance at once \nwith a single payment.',
    createPlanSubtitle: 'Customize a payment schedule \nthat works for you.',
    partialPaymentSubtitle: 'Pay a portion of your balance now \nand address the remainder later.',
    payments: 'Payments',
    monthly: 'Monthly',
    biweekly: 'Biweekly',
    weekly: 'Weekly',
    plans: {
      paymentPlan: 'Payment Plan',
      customPlan: 'Custom Plan',
      payInFull: 'Pay in Full',
      payInFullPlan: 'Pay in Full',
      paymentPartial: 'Partial Plan'
    },
    partialPlan: {
      title: 'Make a partial plan',
      subtitle: 'Select a percentage of your balance or enter an exact amount to make your payment',
      instruction: 'Make a partial plan',
      boxTitle: 'Select the percentage you want to pay',
      inputTitle: 'Enter the amount you want to pay',
      percentagePaid: 'With this payment you would be paying {percentage} of your balance'
    },
    createPayment: {
      title: 'Create a payment plan',
      subtitle: 'Choose your frequency and select how often you want your payments to be scheduled',
      instruction: 'Create a payment plan',
      boxesTitle: 'Select the option that works best for you',
      selectPayment: 'Select payment',
      finalPayment: '*The final payment of your plan may be less than your payments to reflect the remaining balance and avoid overpayment.'
    },
    setupPayment: {
      title: 'Setup your payment',
      subtitle: 'Choose your payment date and securely enter your payment details to complete the transaction',
      instruction: 'Choose your payment date'
    },
    confirmStep: {
      title: 'Confirm and pay',
      description: 'Confirm your payment',
      payInFull: 'Pay in full',
      paymentPlan: 'Create a payment plan'
    },
    successStep: {
      title: 'Congratulations'
    },
    frequencySubtitle: {
      monthly: 'Schedule monthly payments to align with your regular expenses.',
      biweekly: 'Set up payments every two weeks for easier budgeting.',
      weekly: 'Make payments every week to stay on track with your plan.'
    },
    achTabLabel: 'Bank Account (ACH)',
    cardTabLabel: 'Credit / Debit Card',
    cardDetails: 'Card Details',
    bankDetails: 'Bank Account Details',
    printReceipt: 'Print Receipt',
    upcomingPayment: 'Upcoming payment',
    lastPayment: 'Last payment',
    ofBalance: 'of balance',
    choose: 'OR',
    amountToPay: 'Amount to pay'
  },
  es: {
    title: 'Pagos',
    description: 'Elija la mejor opción de pago al considerar el monto del pago, la \nfrecuencia y cómo se ajusta a tu presupuesto.',
    empty: 'No tiene ningún plan de pago',
    installments: 'Cuotas',
    nextPayments: 'Próximos pagos',
    addPaymentMethodCTA: 'Agregue un método de pago',
    payInFull: 'Pague en su totalidad',
    createPlan: 'Cree un plan de pagos',
    partialPayment: 'Cree un plan parcial',
    payInFullSubtitle: 'Pague el total de su saldo de una vez \ncon un solo pago.',
    createPlanSubtitle: 'Personalice un cronograma de pagos \nque funcione para usted.',
    partialPaymentSubtitle: 'Pague una parte de su saldo ahora \ny aborde el resto más adelante.',
    payments: 'Pagos',
    monthly: 'Mensual',
    biweekly: 'Quincenal',
    weekly: 'Semanal',
    plans: {
      paymentPlan: 'Plan de pago',
      customPlan: 'Plan Personalizado',
      payInFull: 'Pague en su totalidad',
      payInFullPlan: 'Pague en su totalidad',
      paymentPartial: 'Plan Parcial'
    },
    partialPlan: {
      title: 'Cree un plan parcial',
      subtitle: 'Seleccione un porcentaje de su saldo o introduzca una cantidad exacta para realizar su pago',
      instruction: 'Cree un plan parcial',
      boxTitle: 'Seleccione el porcentaje que desea pagar',
      inputTitle: 'Ingrese el importe que desea pagar',
      percentagePaid: 'Con este pago, estaría pagando el {percentage} de su saldo'
    },
    createPayment: {
      title: 'Cree un plan de pago',
      subtitle: 'Elija su frecuencia y seleccione la periodicidad de sus pagos',
      instruction: 'Cree un plan de pago',
      boxesTitle: 'Seleccione la opción que mejor le convenga',
      selectPayment: 'Seleccionar pago',
      finalPayment: '*El pago final de su plan puede ser inferior a sus pagos para reflejar el saldo restante y evitar pagar de más.'
    },
    setupPayment: {
      title: 'Configure su pago',
      subtitle: 'Elija su fecha de pago e ingrese de forma segura sus datos de pago para completar la transacción',
      instruction: 'Elija su fecha de pago'
    },
    confirmStep: {
      title: 'Confirmar y pagar',
      description: 'Confirme su pago',
      payInFull: 'Pague en su totalidad',
      paymentPlan: 'Cree un plan de pago'
    },
    successStep: {
      title: 'Felicitaciones'
    },
    frequencySubtitle: {
      monthly: 'Programe pagos mensuales para alinearlos con sus gastos regulares.',
      biweekly: 'Establezca pagos cada dos semanas para facilitar la elaboración de su presupuesto.',
      weekly: 'Realice pagos cada semana para no desviarse de su plan.'
    },
    cardTabLabel: 'Tarjeta de crédito / débito',
    achTabLabel: 'Cuenta bancaria (ACH)',
    cardDetails: 'Detalles de la tarjeta',
    bankDetails: 'Detalles de la cuenta bancaria',
    printReceipt: 'Imprimir recibo',
    upcomingPayment: 'Próximo pago',
    lastPayment: 'Último pago',
    ofBalance: 'del saldo',
    choose: 'O',
    amountToPay: 'Importe a pagar'
  }
};
