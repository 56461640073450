import React, { useEffect, useState, useRef } from 'react';
import { useLocale, useOnce, useQuiqChat, useStateView } from '../../hooks';
import { accountView, activePaymentPlansView, paymentPlanView, referenceView, selectedStepView } from '../../state/views';
import { getPaymentPlans } from '../../service/paymentPlan';
import { formatCurrency, mixpanel, redirect } from '../../utils';

import allMessages from './messages';

import { Button, Carousel, MobileComponent, ProgressBar, Spinner } from '../../components';

import { addPaymentMethod,
  nextPayments,
  paymentPlansEmpty,
  progressBar,
  repaymentOption,
  wrapper,
  stepper,
  title,
  disclosureMessage,
  paymentStepper,
  withPaymentPlan
} from './styles.scss';
import EmptyPage from '../../components/EmptyPage/index.js';
import { MIXPANEL_EVENTS, PAYMENT_PLAN_TYPES, PAYMENT_FREQUENCIES } from '../../constants';
import paths from '../../routes/paths.js';
import { PartialPaymentIcon, PayInFullIcon, PaymentPlanIcon } from '../../components/icons/index.js';
import CreditDisclosure from '../../components/CreditDisclosure/index.js';
import SetupPaymentStep from './Steppers/SetupPaymentStep';

import { Step, Stepper } from '../../components/Stepper';
import ConfirmPaymentStep from './Steppers/ConfirmPaymentStep.js';
import SuccessPaymentStep from './Steppers/SuccessPaymentStep.js';
import InitialStep from './Steppers/InitialStep.js';

const STEPS = {
  initial: 'initial',
  setup: 'setup',
  confirm: 'confirm',
  success: 'success'
}

const PaymentPlanPage = () => {
  const { formatDate, messages } = useLocale(allMessages);
  const [ { accountId: referenceNumber, remainingBalance, ...acount } ] = useStateView(accountView);
  const [ activePaymentPlans, setActivePaymentPlans ] = useStateView(activePaymentPlansView);
  const [ paymentPlan, setPaymentPlan, clearPaymentPlan ] = useStateView(paymentPlanView);
  const [ reference, setReference ] = useStateView(referenceView);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [progress, setProgress] = useState({ value: 0, total: 0 });

  const [currentStep, setCurrentStep] = useStateView(selectedStepView);

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewPaymentPlansPage));

  useQuiqChat({ debtId: referenceNumber });

  const stepperRef = useRef();

  useEffect(() => {
    if (isLoaded) return;
    setIsLoading(true);

    if (!reference) {
      setCurrentStep('');
      clearPaymentPlan();
    };

    if (reference === paths.home) {
      setProgress({ value: 1, total: 4 });
      stepperRef.current.goToStep(1);
      setReference('');
    }

    getPaymentPlans(referenceNumber)
      .then(data => {
        setActivePaymentPlans(data);
        setIsLoading(false);
        setIsLoaded(true);
      });
  }, []);

  const paymentPlans = activePaymentPlans?.paymentPlans;

  const handlePayInFull = () => {
    clearPaymentPlan();
    mixpanel.track(MIXPANEL_EVENTS.selectPayInFullOption);
    setPaymentPlan({
      ...paymentPlan,
      planType: PAYMENT_PLAN_TYPES.payInFull,
      numPayments: 1,
      paymentFrequency: PAYMENT_FREQUENCIES.monthly,
      standardPaymentAmount: remainingBalance,
      totalPaymentAmount: remainingBalance
    });
    setCurrentStep(STEPS.setup);
    setProgress({ value: 1, total: 4 });
    stepperRef.current.goToStep(1);
  };
  
  const handleCreatePlan = () => {
    clearPaymentPlan();
    mixpanel.track(MIXPANEL_EVENTS.selectCreatePaymentPlanOption);
    setPaymentPlan({
      ...paymentPlan,
      planType: PAYMENT_PLAN_TYPES.paymentPlan
    });
    setCurrentStep(STEPS.initial);
    setProgress({ value: 1, total: 6 });
  };
  
  const handlePartialPayment = () => {
    clearPaymentPlan();
    mixpanel.track(MIXPANEL_EVENTS.selectPartialPaymentOption);
    setPaymentPlan({
      ...paymentPlan,
      planType: PAYMENT_PLAN_TYPES.paymentPartial,
      numPayments: 1,
      totalPaymentAmount: remainingBalance
    });
    setCurrentStep(STEPS.initial);
    setProgress({ value: 1, total: 6 });
  };

  const submitCallback = () => {
    setCurrentStep(STEPS.confirm);
    stepperRef.current.goToStep(2);
  }

  return <>
    {isLoading ? <Spinner /> : (
      <div className={wrapper}>
        {!currentStep && <>
          <div className={title}>
            <h1>{messages.title}</h1>
            {(!paymentPlans || paymentPlans.length <= 0) && <p>{messages.description}</p>}
          </div>
          {(!paymentPlans || paymentPlans.length <= 0) && (
            <>
              <MobileComponent
                WebOption={props => <div className={paymentPlansEmpty} {...props}></div>}
                MobileOption={props => <div className={paymentPlansEmpty}><Carousel {...props}></Carousel></div>}
              >
                <a
                  onClick={handlePayInFull}
                  data-action="select pay in full"
                  className={repaymentOption}
                >
                  <PayInFullIcon alt="Hand holding a dollar bill" />
                  <h4>{ messages.payInFull }</h4>
                  <p>{messages.payInFullSubtitle}</p>
                </a>
                <a
                  onClick={handleCreatePlan}
                  data-action="select create plan"
                  className={repaymentOption}
                >
                  <PaymentPlanIcon alt="Schedule" />
                  <h4>{ messages.createPlan }</h4>
                  <p>{messages.createPlanSubtitle}</p>
                </a>
                <a
                  onClick={handlePartialPayment}
                  data-action="select partial payment"
                  className={repaymentOption}
                >
                  <PartialPaymentIcon alt="Hand offering money" />
                  <h4>{ messages.partialPayment }</h4>
                  <p>{messages.partialPaymentSubtitle}</p>
                </a>
              </MobileComponent>
              <CreditDisclosure allMessages={allMessages} />
            </>
          )}
        </>}
        {(!paymentPlans || paymentPlans.length <= 0) && (
          <Stepper name='Payments page step' ref={stepperRef} className={paymentStepper}>
            <Step>
              {currentStep === STEPS.initial && <InitialStep
                progress={progress}
                referenceNumber={referenceNumber}
                remainingBalance={remainingBalance}
                stepperRef={stepperRef}
                setProgress={setProgress}
                setCurrentStep={setCurrentStep}
              />}
            </Step>
            <Step>
              {currentStep === STEPS.setup && <SetupPaymentStep
                referenceNumber={referenceNumber}
                stepperRef={stepperRef}
                currentBalance={remainingBalance}
                progress={progress}
                setProgress={setProgress}
                currentStep={currentStep}
                submitCallback={submitCallback}
              />}
            </Step>
            <Step>
              {currentStep === STEPS.confirm && <ConfirmPaymentStep
                referenceNumber={referenceNumber}
                stepperRef={stepperRef}
                currentBalance={remainingBalance}
                progress={progress}
                setProgress={setProgress}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />}
            </Step>
            <Step>
              {currentStep === STEPS.success && <SuccessPaymentStep
                referenceNumber={referenceNumber}
                currentBalance={remainingBalance}
                progress={progress}
              />}
            </Step>
          </Stepper>
        )}
        {paymentPlans instanceof Array && paymentPlans.length > 0 && (
          <>
            <div className={withPaymentPlan}>
              {paymentPlans.map(plan => {
                const {
                  planId,
                  isPromise,
                  paymentSchedule,
                  originalBalance,
                  remainingBalance
                } = plan;

                const alreadyPaid = originalBalance - remainingBalance;

                return (
                  <section key={`payment-plan-${referenceNumber}-${planId}`}>
                    <h3>#{planId}</h3>
                    <hr />
                    <p>
                      <strong>{ messages.remainingBalance }: </strong>
                      { formatCurrency(remainingBalance) }
                    </p>
                    <p>
                      <strong>{ messages.installments }: </strong>
                      { paymentSchedule.length }
                    </p>
                    { isPromise && <div className={addPaymentMethod}><Button href={paths.paymentPlanSetup}>{ messages.addPaymentMethodCTA }</Button></div> }
                    <hr />
                    <div className={progressBar}>
                      <p>
                        { messages.alreadyPaid }
                        <strong> { formatCurrency(alreadyPaid) } </strong>
                        { messages.of }
                        <strong> { formatCurrency(originalBalance) } </strong>
                      </p>
                    </div>
                    <hr />
                    <p><strong>{ messages.nextPayments }</strong></p>
                    <div className={nextPayments}>
                      {paymentSchedule.map(p => {
                        if (p.paymentStatus !== 'PENDING') {
                          return null;
                        }

                        const date = new Date(p.paymentDate);

                        return <div>
                          <p>{ formatDate(date, { day: 'numeric' }) }</p>
                          <p>{ formatDate(date, { month: 'short' }) }</p>
                          <p><strong>{ formatCurrency(p.paymentAmount) }</strong></p>
                        </div>;
                        })}
                    </div>
                  </section>
                )
              })}
            </div>
            <CreditDisclosure allMessages={allMessages} />
          </>
        )}
      </div>
    )}
  </>;
};

export default PaymentPlanPage;
