import { Store } from '../utils';
import { credentialsChanged } from './views';

const initialState = {
  session: {
    authToken: '',
    expires: 0,
    authenticated: false
  },
  locale: navigator?.language?.replace(/-.*$/g, '') || 'en',
  redirectUrl: '',
  referenceNumbers: [],
  selectedReferenceNumber: null,
  account: {
    // TODO fix up this initial state, it's wrong
    // (check API reference)
    referenceNumber: null,
    verified: false,
    disclosures: {
      solDisclosureID: 0,
      currentCreditorName: ''
    }
  },
  activePaymentPlans: null,
  paymentHistory: null,
  documents: null,
  paymentPlan: {
    planType: null,
    numPayments: 0,
    totalPaymentAmount: null,
    standardPaymentAmount: null,
    lastPaymentAmount: null,
    paymentFrequency: null,
    paymentDayOfWeek: null,
    paymentStartDate: null,
    paymentStartDateLimit: 14,
    paymentSchedule: []
  },
  defaultSpecialPlan: {
    planType: null,
    numPayments: 0,
    totalPaymentAmount: null,
    standardPaymentAmount: null,
    lastPaymentAmount: null,
    paymentFrequency: null,
    paymentDayOfWeek: null,
    paymentStartDate: null,
    paymentStartDateLimit: 14,
    paymentSchedule: []
  },
  settlementOffers: {
    currentBalance: null,
    offerAmount: null,
    plans: []
  },
  paymentMethod: {
    consumerName: null,
    paymentSource: null,
    type: null,
    cardType: null,
    routingNumber: null,
    pnRef: null,
    result: null,
    last4: null,
    token: null,
    date: null,
    referenceNumber: null
  },
  shortLink: {
    referenceNumber: null,
    ref: null,
    shortUrl: null,
    fullUrlPath: null,
    verified: null,
    preferredLanguage: null,
    disclosureData: {
      currentCreditorName: null,
      isPastSol: null,
      isCreditReportingEnabled: null
    }
  },
  menuToggled: false,
  accountsInformation: [],
  negotiatedPlan: {
    referenceNumber: null,
    currentBalance: null,
    offerAmount: null,
    planType: null,
    proposedAmountApproved: true,
    planOptions: {}
  },
  selectedPlanType: '',
  comesFromSplashPage: false,
  selectedStep: '',
  reference: ''
};

const store = new Store({ initialState, storageKey: 'soc-portal' });

export default store;
export { initialState };
