import React, { useMemo } from 'react';
import { useLocale, useStateView } from '../../hooks';
import {
  accountView,
  activePaymentPlansView,
  defaultSpecialPlanView,
  paymentMethodView,
  paymentPlanView,
  selectedPlanTypeView
} from '../../state/views';

import {
  CREDITOR_NOT_AVAILABLE,
  NEGOTIABLE_PLAN_TYPES,
  PAYMENT_FREQUENCIES,
  PAYMENT_INFO_FORMAT,
  PAYMENT_METHODS,
  PAYMENT_PLAN_MIXPANEL_TYPES,
  PAYMENT_PLAN_TYPES
} from '../../constants';

import allMessages from './messages.js';

import { paymentInfo, paymentMethodRow, totalBalanceRow } from './styles.scss';
import { formatCurrency } from '../../utils';
import PaymentSummary from '../PaymentSummary';

import {
  BankIcon,
  CalendarIcon,
  CardIcon,
  PartialPaymentIcon,
  PayInFullIcon,
  PaymentPlanIcon
} from '../icons';
import { weekdayString } from '../../utils/date';
import OrdinalNumber from '../OrdinalNumber/index.js';
import PlanDetailBox from '../PlanDetailBox/index.js';
import PaymentFrequencyMessage from '../PaymentFrequencyMessage/index.js';
import Button from '../Button/index.js';
import SettlementDetailBox from '../SettlementDetailBox/index.js';

const PaymentInfo = ({
  isSuccessPage = false,
  isConfirmPage,
  hasConfirmButton,
  loading,
  submitPayment = () => {},
  buttonClassName,
  onlyTable = false,
  tableClassName,
  isSettlement,
  settlementOffers
}) => {
  const { formatDate, formatMessage, locale, messages } = useLocale(allMessages);

  const [ account ] = useStateView(accountView);
  const [ paymentPlan ] = useStateView(paymentPlanView);
  const [ paymentMethod ] = useStateView(paymentMethodView);
  const [ selectedPlanType ] = useStateView(selectedPlanTypeView);
  const [ defaultSpecialPlan ] = useStateView(defaultSpecialPlanView);

  const { clientName } = account;

  const {
    planType,
    paymentFrequency,
    paymentDayOfWeek,
    paymentStartDate,
    paymentSchedule,
    numPayments,
    totalPaymentAmount
  } = paymentPlan;

  const referenceNumber = paymentPlan.referenceNumber || account.accountId;

  const isPromise = !planType;

  const {
    cardType,
    consumerName,
    last4,
    paymentSource,
    routingNumber,
    type
  } = paymentMethod;

  const startDateFormat = numPayments === 1 ? { month: 'long', day: 'numeric' } : { month: 'long', day: 'numeric', year: 'numeric' };

  const startDatePayment = formatDate(paymentPlan.paymentStartDate, startDateFormat);
  const upcomingPaymentMessage = `${messages.upcomingPayment} 1 ${messages.of} ${numPayments} (${messages.dueOn} ${startDatePayment})`;

  const lastDatePayment = formatDate(paymentSchedule.at(-1).date, { month: 'long', day: 'numeric', year: 'numeric' });
  const lastPaymentMessage = `${messages.lastPayment} ${lastDatePayment}`;

  const formattedCardType = cardType && cardType.charAt(0).toUpperCase() + cardType.slice(1).toLowerCase();
  const frequencyMessage = !isPromise && <PaymentFrequencyMessage />;

  const isCustomPlan = numPayments > 1;

  const isSettlementPlan = PAYMENT_PLAN_TYPES.settlementPlan === planType;

  return (
    <>
      {!onlyTable && <>
        {isSettlement ?
          <SettlementDetailBox
            showDate={isConfirmPage || isSuccessPage}
            settlementOffers={settlementOffers}
            referenceNumber={referenceNumber}
            isSettlementPlan={isSettlementPlan}
            isConfirmPage={isConfirmPage}
            isSuccessPage={isSuccessPage}
          />
          : <PlanDetailBox
            currentBalance={!isCustomPlan && defaultSpecialPlan.totalPaymentAmount}
            planType={messages[selectedPlanType]}
            referenceNumber={referenceNumber}
            paymentPlan={paymentPlan}
            upcomingPayment={upcomingPaymentMessage}
            lastPayment={lastPaymentMessage}
            paymentFrequency={frequencyMessage}
            isSuccessPage={isSuccessPage}
            isConfirmPage={isConfirmPage}
            fullPayment={!isCustomPlan}
          />
        }

        {hasConfirmButton && 
          <div className={buttonClassName}>
            <Button
              data-action="confirm payment"
              onClick={submitPayment}
              loading={loading}
              disabled={loading}
              variant={[ 'small', 'primary' ]}
            >
              {messages.confirm}
            </Button>
          </div>
        }
      </>}

      <div className={tableClassName}>
        <table className={paymentInfo}>
          <tbody>
            <tr>
              <th>{messages.originalCreditor}</th>
              <td><strong>{!clientName || clientName === CREDITOR_NOT_AVAILABLE ? messages.notAvailable : clientName}</strong></td>
            </tr>
            <tr>
              <th>{messages.referenceNumber}</th>
              <td><strong>{referenceNumber}</strong></td>
            </tr>
            <tr>
              <th>{messages.consumerName}</th>
              <td><strong>{consumerName}</strong></td>
            </tr>
            <tr className={paymentMethodRow}>
              <th>{messages.paymentMethod}</th>
              <td>
                {type === PAYMENT_METHODS.ach
                    ? <>{messages.bankAccount}</>
                    : <>{formattedCardType}</> } {messages.endingIn} <strong>{ last4 }</strong>
              </td>
            </tr>
            <tr className={totalBalanceRow}>
              <th>{messages.totalBalance}:</th>
              <td><strong>{formatCurrency(totalPaymentAmount)}</strong></td>
            </tr>

            {hasConfirmButton && 
              <div className={buttonClassName}>
                <Button
                  data-action="confirm payment"
                  onClick={submitPayment}
                  loading={loading}
                  disabled={loading}
                  variant={[ 'small', 'primary' ]}
                >
                  {messages.confirm}
                </Button>
              </div>
            }
          </tbody>
        </table>
      </div>
    </>
  )
};

export default PaymentInfo;
