import React from 'react';

import {
  stepper,
  planBoxWrapper,
  planBox,
  mostOffered,
  tag,
  planTag,
  primaryText,
  secondaryText,
  primaryButton,
  message,
  chooseHalfline,
  line,
  or,
  secondaryButton,
  isInitialStep,
} from '../styles.scss'

import allMessages from '../messages';
import { Button, Carousel, MobileComponent, PaymentSummary } from '../../../components';

import stepOneSvg from '../../../static/images/icons/stepOne.svg';
import { cn } from '../../../utils';
import { useLocale } from '../../../hooks';

const InitialStep = ({
  initial,
  referenceNumber,
  paymentPlan,
  defaultSpecialPlan,
  handleClickAcceptDefault,
  handleClickCreatePlan,
  handleClickPayInFull
}) => {
  const { formatMessage, messages } = useLocale(allMessages);

  return (
    <>
      <div className={cn(stepper, isInitialStep)}>
        <img src={stepOneSvg} />
      </div>
      <h1>{messages.title}</h1>
      <p>{initial.planIntro}</p>

      <MobileComponent
        WebOption={props => <div className={planBoxWrapper} {...props}></div>}
        MobileOption={props => <div className={planBoxWrapper}><Carousel {...props}></Carousel></div>}
      >
        <div className={cn(planBox, mostOffered)}>
          <div className={tag}>{messages.mostChosen}</div>
          <span className={planTag}>{messages.paymentPlan}</span>
          <PaymentSummary
            paymentPlan={defaultSpecialPlan}
            finalAmountClass={primaryText}
            extraPaymentClass={secondaryText}
          />
          <Button
            className={primaryButton}
            data-action="accept default plan"
            onClick={handleClickAcceptDefault} 
            data-goto-step={2}
          >
            {initial.acceptDefaultPlan}
          </Button>
          <p className={message}>
            {formatMessage(initial.instructions, {
              acceptButtonText: <b>{initial.acceptDefaultPlan}</b>,
              createButtonText: <b>{initial.createPlan}</b>
            })}
          </p>
        </div>

        <div className={planBox}>
          <span className={planTag}>{messages.customPlan}</span>
          <div className={primaryText}>
            {messages.currentBalance}
            <div>$ <span>{defaultSpecialPlan.totalPaymentAmount}</span></div>
          </div>
          <div className={secondaryText}>
            {messages.referenceNumber} <b>{referenceNumber}</b>
            <Button
              className={primaryButton}
              data-action="create payment plan"
              onClick={handleClickCreatePlan}
              data-next-step
            >
              {initial.createPlan}
            </Button>
          </div>
          <div className={chooseHalfline}>
            <div className={line} />
            <div className={or}>{messages.choose}</div>
            <div className={line} />
          </div>
          <Button
            className={secondaryButton}
            data-action="pay in full"
            onClick={handleClickPayInFull}
            data-next-step
          >
            {initial.payInFull}
          </Button>
        </div>
      </MobileComponent>
    </>
  );
}

export default InitialStep;
